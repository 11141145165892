<template>
  <div>
    <md-dialog :md-active.sync="isModalVisibleTab" class="md-dialog-tab">
      <subscription-modal
      limitType="follow"></subscription-modal>
    </md-dialog>

  </div>
</template>

<script>
import SubscriptionModal from './SubscriptionModal.vue';

export default {
  name: 'DoFollowLinksModal',
  components: {
    SubscriptionModal,
  },
  props: {
    isModalVisibleTab: Boolean,
  },
};
</script>


<style scoped>
.icon-space-right {
  margin-right: 15px;
}

.align-left {
  padding-left: 35px !important;
}

.margin-to-align {
  margin: 10px 0px 0px 10px;
}

.inline-width {
  width: 75%;
}
</style>
