<template>
  <div class="md-card account-dashboard">
    <div class="md-layout">
      <div class="md-layout-item user-info md-size-50 md-small-size-100">
        <div class="md-layout">
          <div class="md-layout-item">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <div class="md-layout md-gutter md-alignment-center-center">
                  <div class="md-layout-item md-alignment-center md-size-30">
                    <img :src="user.userProfileImagePath" class="profile-pic" v-if="user.userProfileImagePath">
                    <img :src="`${publicPath}profile-image.png`" class="profile-pic" v-else>
                  </div>
                  <div class="md-layout-item md-alignment-center md-size-70 md-xsmall-size-70">
                    <p class="user-name">{{ user.username }}</p>
                    <a class="pointer" @click="$refs.file.click()">
                      <input type="file" ref="file" id="file" class="pointer" data-count="0" style="display: none;"
                             @change="onFileChange">{{ $t('account.upload') }}</a>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 my-divider md-xsmall-size-100"></div>
              <div class="md-layout-item md-size-95 md-small-size-100">
                <div class="md-layout md-alignment-center md-gutter input-account-container">
                  <div class="md-layout-item md-size-80 account-input  md-xsmall-size-90">
                    <md-field class="md-layout-item" style="display: flex;">
                      <p class="input-label md-layout-item md-size-20"> {{ $t('account.username') }}</p>
                      <md-input name="username" id="username" class="md-input-username md-layout-item md-size-70 input-with-border"
                                v-model="username"
                                placeholder="Username" @change="saveChanges('username')"/>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-80 account-input md-xsmall-size-90">
                    <md-field class="md-layout-item" style="display: flex;">
                      <p class="input-label md-layout-item md-size-20">{{ $t('account.mail') }}</p>
                      <md-input name="username" id="email" class="md-input-email md-layout-item md-size-70 input-with-border"
                                v-model="email" placeholder="Email"
                                type="email" @change="saveChanges('email')"/>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-80 account-input md-xsmall-size-90">
                    <md-field class="md-layout-item" style="display: flex;">
                      <p class="input-label md-layout-item md-size-20">{{ $t('Password') }}</p>
                      <md-input name="password" id="password" class="md-input-email md-layout-item md-size-70 input-with-border"
                                v-model="password" placeholder="************"
                                type="text" @change="saveChanges('password')"/>
                    </md-field>
                  </div>
                </div>
              </div>

              <div class="md-layout-item md-size-95 md-small-size-100">
                <div class="md-layout md-alignment-center-space-around">
                  <div class="md-layout-item md-size-80 md-xsmall-size-90">
                    <md-checkbox v-model="privateAccount" @change="saveChanges('privateaccount')">
                      {{ $t('account.privateAccount') }}
                    </md-checkbox>
                  </div>
                  <div class="md-layout-item  md-size-80 md-xsmall-size-90">
                    <md-checkbox v-model="notifications" @change="saveChanges('notifications')"> {{ $t('account.notifications') }}
                    </md-checkbox>
                  </div>
                  <div class="md-layout-item  md-size-80 md-xsmall-size-90">
                    <md-checkbox v-model="updates" @change="saveChanges('updates')"> {{ $t('account.updates') }}
                    </md-checkbox>
                  </div>
                  <div class="md-layout-item  md-size-80 md-xsmall-size-90">
                    <label style="font-size: 16px; font-weight: 700;">SEO Tools</label>
                    <br>
                    <md-checkbox v-model="followLinks" @change="saveChanges('follow_links')"> Enable Do-Follow Links
                    </md-checkbox>
                  </div>
                </div>
              </div>
              <div
                class="md-layout-item md-size-95 md-layout md-gutter my-class md-small-size-100 buttons-container-account">
                <div class="md-layout-item logout-button md-small-size-50" @click="logout">
                  <a class="pointer">{{ $t('logout') }}</a></div>
                <div class="md-layout-item md-small-size-40 delete-account-button"  @click="deleteAccountConfirmation = true"><a
                  class="pointer">{{ $t('account.deleteAccount') }}</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isMobile" class="md-layout-item md-size-50 right-section right-section-text md-small-hide big-text-wrapper">
        <h1 class="big-text">
          <span>{{ $t('account.text1') }}</span>
          <span>{{ $t('account.text2') }}</span>
          <span class="pink-text">{{ $t('account.text3') }}</span>
        </h1>
      </div>
    </div>

    <md-dialog-confirm class="account-delete-confirmation delete-tab"
                       :md-active.sync="deleteAccountConfirmation"
                       md-title="Delete Account"
                       md-content="Do you want to delete your account?"
                       md-confirm-text="Yes"
                       md-cancel-text="No"
                       @md-confirm="deleteAccount" />

    <do-follow-links-modal :isModalVisibleTab="this.showSubscriptionModal"
      limitType="tabs"></do-follow-links-modal>

  </div>
</template>

<script>
import { DELETE_USER, UPDATE_USER, UPLOAD_PROFILE_USER_IMAGE } from '@/store/modules/user/userActions.type';
import { LOGOUT } from '@/store/modules/auth/authActions.type';
import DoFollowLinksModal from '@/components/modals/DoFollowLinksModal.vue';
import smallDevicesCheckMixin from '@/mixins/smallDevicesCheckMixin';

export default {
  name: 'Account',
  components: {
    DoFollowLinksModal,
  },
  mixins: [
    smallDevicesCheckMixin,
  ],
  data: () => ({
    publicPath: process.env.BASE_URL,
    file: null,
    fileId: null,
    deleteAccountConfirmation: false,
    userPassword: null,
    showSubscriptionModal: false,
  }),
  mounted() {
    this.checkDevice();
    this.smallDeviceCheck();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isPaidUser() {
      return this.user !== null && this.user.plan_id != null;
    },
    isPremiumUser() {
      return this.user !== null && this.user.premium != null;
    },
    isAdminUser() {
      return this.user !== null && this.user.role === 'admin';
    },
    hasSubscription() {
      return this.isPaidUser || this.isPremiumUser || this.isAdminUser;
    },
    username: {
      get() {
        return this.user.username;
      },
      set(value) {
        this.user.username = value;
      },
    },
    email: {
      get() {
        return this.user.email;
      },
      set(value) {
        this.user.email = value;
      },
    },
    password: {
      get() {
        return this.userPassword;
      },
      set(value) {
        this.userPassword = value;
      },
    },
    privateAccount: {
      get() {
        return !!this.user.privacy;
      },
      set(value) {
        this.user.privacy = value;
      },
    },
    notifications: {
      get() {
        return !!this.user.privacynotifylogo;
      },
      set(value) {
        this.user.privacynotifylogo = value;
      },
    },
    updates: {
      get() {
        return !!this.user.nlsignup;
      },
      set(value) {
        this.user.nlsignup = value;
      },
    },
    followLinks: {
      get() {
        return !!this.user.follow_links;
      },
      set(value) {
        this.user.follow_links = value;
      },
    },
    userProfileImagePath: {
      get() {
        return this.user.userProfileImagePath;
      },
      set(value) {
        this.user.userProfileImagePath = value;
      },
    },
  },
  methods: {
    onFileChange(e) {
      const image = e.target.files[0];
      this.file = image;
      this.saveImage(image);
      // this.createImage(image);
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form.uploadedFilePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    saveChanges(field) {
      this.showSubscriptionModal = false;
      let payload = {
        id: this.user.id,
      };
      switch (field) {
        case 'username':
          // eslint-disable-next-line no-case-declarations
          const username = { username: this.username };
          payload = { ...payload, ...username };
          break;
        case 'email':
          // eslint-disable-next-line no-case-declarations
          const email = { email: this.email };
          payload = { ...payload, ...email };
          break;
        case 'password':
          // eslint-disable-next-line no-case-declarations
          const password = { password: this.userPassword, change_password: true };
          payload = { ...payload, ...password };
          break;
        case 'privateaccount':
          // eslint-disable-next-line no-case-declarations
          const privateAccount = { privacy: this.privateAccount };
          payload = { ...payload, ...privateAccount };
          break;
        case 'notifications':
          // eslint-disable-next-line no-case-declarations
          const notifications = { privacynotifylogo: this.notifications };
          payload = { ...payload, ...notifications };
          break;
        case 'updates':
          // eslint-disable-next-line no-case-declarations
          const updates = { nlsignup: this.updates };
          payload = { ...payload, ...updates };
          break;
        case 'profileImage':
          // eslint-disable-next-line no-case-declarations
          const profileImage = { profile_picture_id: this.fileId };
          payload = { ...payload, ...profileImage };
          break;
        case 'follow_links':
          if (this.followLinks && !this.hasSubscription) {
            payload = null;
            this.followLinks = false;
            this.showSubscriptionModal = true;
            break;
          }

          // eslint-disable-next-line no-case-declarations
          const doFollowLinks = { follow_links: this.followLinks };
          payload = { ...payload, ...doFollowLinks };
          break;
        default:
          break;
      }
      if (payload) {
        this.$store.dispatch(UPDATE_USER, payload)
          .then((response) => {
            this.$log.info(UPDATE_USER, payload);
            this.$toasted.show(response.message, {
              type: 'success',
              position: 'top-right',
              duration: 5000,
              icon: 'check',
            });
          })
          .catch((error) => {
            this.$log.error(UPDATE_USER, error);
            this.$toasted.show(error.message, {
              type: 'error',
              position: 'top-right',
              duration: 5000,
              icon: 'alert',
            });
            if (error.status === 'validation_error' && error.errors) {
              this.errors = error.errors;
            }
          });
      }
    },
    logout() {
      this.$store.dispatch(LOGOUT)
        .then((response) => {
          this.$log.info('LOGOUT', response);
        })
        .catch((error) => {
          this.$toasted.show('You\'ve been disconnected. Goodbye!', {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
          this.$log.error('LOGOUT', error);
        });
    },
    deleteAccount() {
      if (this.user.id) {
        this.$store.dispatch(DELETE_USER, this.user.id)
          .then((response) => {
            this.$log.info('DELETE_USER', response);
            this.$toasted.show(response.message, {
              type: 'success',
              duration: 5000,
              icon: 'check',
            });
            this.logout();
          })
          .catch((error) => {
            this.$log.error('DELETE_USER', error);
            this.$toasted.error(error.message, {
              type: 'error',
              duration: 5000,
              icon: 'alert',
            });
          });
      }
    },
    saveImage(image) {
      this.file = image;
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('user_id', this.user.id);
      // formData.append('tab_id', 1);
      formData.append('context', 'USER_PROFILE_IMAGE');
      this.$store.dispatch(UPLOAD_PROFILE_USER_IMAGE, formData)
        .then((response) => {
          this.$log.info('UPLOAD_PROFILE_USER_IMAGE', response);
          this.fileId = response.data.file.id;
          this.userProfileImagePath = response.data.file.public_path;
          this.saveChanges('profileImage');
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error('UPLOAD_PROFILE_USER_IMAGE', error);
          this.$toasted.error(error.message, {
            type: 'error',
            duration: 5000,
            icon: 'alert',
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/layout/user-dashboard.scss';
</style>
